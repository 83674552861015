
import { createPinia } from "pinia";
import { createPersistedState } from "pinia-plugin-persistedstate";

const pinia = createPinia()
/**
 * 设置pinia持久性保存在本地缓存中
 */
pinia.use(createPersistedState({
    storage: sessionStorage,
    key: id => `__persisted__${id}`,
}))
export default pinia