import { defineStore } from "pinia";
/**
 * 判断当前登陆用户
 */
export const useAuthorityStore = defineStore('authority', () => {
  const authorityIndex = ref(true);
  const determineRouting = ref(true); // 判断当前是否需要添加动态路由
  /**
   * 设置当前用户角色
   * @param item 条件
   */
  const selectAuthorityFu = item => {
    authorityIndex.value = item;
  };
  const setDetermineRouting = item => {
    determineRouting.value = item;
  };
  return {
    authorityIndex,
    selectAuthorityFu,
    determineRouting,
    setDetermineRouting
  };
}, {
  persist: true
});