import { defineStore } from "pinia";
import { useAuthorityStore } from "./authority";
class defineUserData {}
export const useUserStore = defineStore('use', () => {
  const useAuthority = useAuthorityStore();
  const token = ref('');
  const userData = ref(new defineUserData());
  const setTokenFu = data => {
    token.value = data;
  };
  const setUserDataFu = data => {
    userData.value = data;
  };
  /**
   * 重置数据
   */
  const resetState = () => {
    Object.assign(userData, new defineUserData());
    token.value = '';
    useAuthority.setDetermineRouting(true);
  };
  return {
    token,
    userData,
    setTokenFu,
    setUserDataFu,
    resetState
  };
}, {
  persist: true
});