import { isMobile } from "./utils"
function remSize() {
    let deviceWidth
    /**
     * 判断是否为pc端
     */
    if (isMobile()) {
        deviceWidth = document.documentElement.clientWidth || window.innerWidth
    } else {
        deviceWidth = 375
    }
    document.querySelector<HTMLElement>('html')!.style.width = deviceWidth + 'px'
    document.querySelector<HTMLElement>('html')!.style.fontSize = deviceWidth / 375 + 'px';
}
remSize()
//当窗口发生变化时要调用函数
window.onresize = function () {
    remSize()
}