import { showLoadingToast } from 'vant';

export function isMobile() {
    if ((navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i))) {
        return true // 手机端
    } else {
        return false // alert('PC端')
    }
}

/**
 * 设置输入框只能输入数字字母
 * @param data 需要处理的数据
 * @returns 返回出口之后的数据
 */
export function alphanumericFu(data: any) {
    // 移除非数字和字母以外的字符
    return data.replace(/[^0-9A-Za-z]/g, '');
}
/**
 * 设置密码框
 * @param data 需要处理的数据
 * @returns 返回出口之后的数据
 */
export function passwordFu(data: any) {
    return data.replace(/[\u4E00-\u9FA5]/g, '')
}
/**
 * 设置只能输入手机号
 */
export function phoneFu(data: any) {
    if (data.length == 1) {
        return data = data.replace(/[^1]/g, '');
    } else {
        return data = data.replace(/\D/g, '');
    }
}

/**
 * 设置全局loading动画
 */

// 全局加载动画

export const loadingFu = (duration = 0, text?: string) => {
    const loading = showLoadingToast({
        message: text || '加载中...',
        duration, // 持续展示 toast
        forbidClick: true,
        className: 'z_index'
    });
    return loading
}

/**
 * 日期格式转换
*/

export const dateStrToDateFormat = (str: string, format = 'yyyy-mm-dd') => {
    if (!str) return ''

    const date = new Date(str)
    const year = date.getFullYear()
    let month = (date.getMonth() + 1) + ''
    let day = date.getDate() + ''
    let hours = date.getHours() + ''
    let minutes = date.getMinutes() + ''
    let seconds = date.getSeconds() + ''
    month = month.padStart(2, '0')
    day = day.padStart(2, '0')
    hours = hours.padStart(2, '0')
    minutes = minutes.padStart(2, '0')
    seconds = seconds.padStart(2, '0')

    if (format == 'yyyy-mm-dd') {
        return `${year}-${month}-${day}`
    } else {
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
    }
}