import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import vant, { showToast, setToastDefaultOptions, closeToast } from 'vant'
import pinia from "./store";
import { loadingFu } from "@/utils/utils";
import Vconsole from 'vconsole'
// 2. 引入组件样式
import 'vant/lib/index.css';
import '@/utils/setRem'
// 重置默认css
import "@/style/reset.css"

/**
 * Vue 定义自定义指令:防止按钮重复提交
*/
const app = createApp(App);
setToastDefaultOptions({ duration: 2000 });
setToastDefaultOptions({ forbidClick: true });
setToastDefaultOptions({ className: 'z_index' });
app.config.globalProperties.$Toast = showToast;
app.config.globalProperties.$Loading = loadingFu
app.config.globalProperties.$CloseToast = closeToast
app.config.globalProperties.$persist = true
// 注册一个全局自定义指令 `v-foc
app.directive('preventReClick', {
    mounted(el: any, binding: any) {
        el.addEventListener('click', () => {
            el.classList.add("pointer");
            if (el.classList.contains('pointer')) {
                setTimeout(() => {
                    el.classList.remove("pointer");
                }, binding.value || 1000)
            }
        })
    }
})

// 配置调试工具
// 如果在开发环境下，引入移动端console调试插件
if (process.env.NODE_ENV !== "production") {
    const vConsole: any = new Vconsole();
    app.use(vConsole)
}

app.use(router).use(vant).use(pinia).mount("#app");

