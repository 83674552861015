const boosRoutes: any = [
    {
        path: "/loanDetails",
        name: "loanDetails",
        component: () => import('@/views/bossPage/loanDetails/index.vue'),
        meta: { title: '放款明细', returnBtn: false, judge: 'boos' }
    },
    {
        path: '/todayData',
        component: () => import('@/views/layout/index.vue'),
        redirect: '/layout/todayData',
        name: 'todayData',
        children: [
            {
                path: '/todayData',
                component: () => import('@/views/bossPage/todayData/index.vue'),
                name: 'layoutTodayData',
                meta: { title: '今日数据', returnBtn: false }
            },
        ]
    },
    {
        path: '/overallData',
        component: () => import('@/views/layout/index.vue'),
        redirect: '/layout/overallData',
        name: 'overallData',
        children: [
            {
                path: '/overallData',
                component: () => import('@/views/bossPage/overallData/index.vue'),
                name: 'layoutOverallData',
                meta: { title: '总量数据', returnBtn: false }
            },
        ]
    },
];
export default boosRoutes;
