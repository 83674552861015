import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import { useAuthorityStore } from '@/store/modules/authority'
import { useUserStore } from "@/store/modules/user";
import boosRoutes from "./boss";
import carRoutes from "./car";

const routes: Array<RouteRecordRaw> = [
    {
        path: "/",
        name: "login",
        component: () => import("@/views/login/index.vue"),
        meta: { title: '身份认证', returnBtn: false }
    },
    {
        path: "/home",
        name: "home",
        component: () => import('@/views/home/index.vue'),
        meta: { title: '身份认证', returnBtn: false }
    },
    {
        path: "/setUp",
        name: "setUp",
        component: () => import('@/views/setUp/index.vue'),
        meta: { title: '设置', returnBtn: true }
    },
    {
        path: "/changePassword",
        name: "changePassword",
        component: () => import('@/views/changePassword/index.vue'),
        meta: { title: '修改密码', returnBtn: true }
    },
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});

/**
 * 路由拦截
 *
 */

let load = 0
router.beforeEach((to, from, next) => {
    const useAuthority = useAuthorityStore()
    const useUser = useUserStore()
    if (load == 0 || useAuthority.determineRouting) {
        if (useUser.token) {
            load++
            const rouetrList: any = useAuthority.authorityIndex ? boosRoutes : carRoutes
            rouetrList.forEach((item: any) => {
                router.addRoute(item)
            })
            useAuthority.setDetermineRouting(false)
            next({ ...to, replace: true })
        } else {
            if (to.path == '/') {
                next()
            } else {
                next('/')
            }
        }
    } else {
        console.log(useUser.token);
        if (useUser.token) {
            if (to.matched.length === 0) {
                next('/todayData')
            } else if (to.path == '/') {
                next('/todayData')
            } else {
                next()
            }
        } else {
            if (to.path == '/') {
                next()
            } else {
                next('/')
            }
        }
    }

})

export default router;
